import {
    useQuery,
    DocumentNode,
    useMutation,
    useLazyQuery, useApolloClient,
} from '@apollo/client';
import {useEffect, useMemo, useState} from 'react';
import {useForm, FieldValues} from 'react-hook-form';
import {navigate} from 'gatsby';
import {
    VideoAttribute,
    videoAttributes,
    VideoAttributeQueryType,
} from '../../../interfaces/videoAttributes';
import {
    GetCategoryAttributeDocument,
    GetThemeAttributeDocument,
    GetTypeAttributeDocument,
    GetUploadPolicyAttributeDocument,
    EditCategoryAttributeDocument,
    EditThemeAttributeDocument,
    EditTypeAttributeDocument,
    EditUploadPolicyAttributeDocument,
    TeamLevel,
    DeleteThemeAttributeDocument,
    DeleteCategoryAttributeDocument,
    DeleteTypeAttributeDocument,
    DeleteUploadPolicyAttributeDocument,
    GetPlaylistTypeDocument,
    EditPlaylistTypeDocument,
    DeletePlaylistTypeDocument,
    GetUploadPolicyParametersDocument,
    CheckThemeNameExistsDocument,
    CheckCategoryNameExistsDocument,
    CheckVideoTypeNameExistsDocument, CheckUploadPolicyNameExistsDocument, CheckPlaylistTypeNameExistsDocument,
    GetSeriesGroupAttributeDocument,
    EditSeriesGroupAttributeDocument,
    DeleteSeriesGroupAttributeDocument,
    CheckSeriesGroupNameExistsDocument,
    GetSeasonSubgroupAttributeDocument,
    EditSeasonSubgroupAttributeDocument,
    DeleteSeasonSubgroupAttributeDocument,
    CheckSeasonSubgroupNameExistsDocument,
} from '../../../graphql-types';
import {Layout, StatusBar} from '../../../components/layout';
import {
    Button,
    Form,
    Input,
    MultiSelectBox,
    Tooltip,
    WarningPopup,
} from '../../../components/core';
import {DeleteIcon, NavArrowLeftIcon} from '../../../components/core/icons';
import Switches from '../../../components/partials/switches/switches';
import toastify from '../../../helpers/toast/toastify';
import * as styles from './[id].module.css';
import * as layoutStyles from '../../../components/layout/layout.module.css';

type QueryConfig = {
    [key in VideoAttribute]: {
        query: DocumentNode,
        editMutation: DocumentNode,
        deleteMutation: DocumentNode,
        checkNameQuery: DocumentNode,
        checkNameVariable: string,
        checkNameExcludeKey: string,
    }
};

const queryConfig: QueryConfig = {
    theme: {
        query: GetThemeAttributeDocument,
        editMutation: EditThemeAttributeDocument,
        deleteMutation: DeleteThemeAttributeDocument,
        checkNameQuery: CheckThemeNameExistsDocument,
        checkNameVariable: 'themeAttributeNameExists',
        checkNameExcludeKey: 'excludeThemeId',
    },
    category: {
        query: GetCategoryAttributeDocument,
        editMutation: EditCategoryAttributeDocument,
        deleteMutation: DeleteCategoryAttributeDocument,
        checkNameQuery: CheckCategoryNameExistsDocument,
        checkNameVariable: 'categoryAttributeNameExists',
        checkNameExcludeKey: 'excludeCategoryId',
    },
    type: {
        query: GetTypeAttributeDocument,
        editMutation: EditTypeAttributeDocument,
        deleteMutation: DeleteTypeAttributeDocument,
        checkNameQuery: CheckVideoTypeNameExistsDocument,
        checkNameVariable: 'typeAttributeNameExists',
        checkNameExcludeKey: 'excludeTypeId',
    },
    uploadPolicy: {
        query: GetUploadPolicyAttributeDocument,
        editMutation: EditUploadPolicyAttributeDocument,
        deleteMutation: DeleteUploadPolicyAttributeDocument,
        checkNameQuery: CheckUploadPolicyNameExistsDocument,
        checkNameVariable: 'uploadPolicyAttributeNameExists',
        checkNameExcludeKey: 'excludeUploadPolicyId',
    },
    playlistType: {
        query: GetPlaylistTypeDocument,
        editMutation: EditPlaylistTypeDocument,
        deleteMutation: DeletePlaylistTypeDocument,
        checkNameQuery: CheckPlaylistTypeNameExistsDocument,
        checkNameVariable: 'playlistTypeNameExists',
        checkNameExcludeKey: 'excludePlaylistTypeId',
    },
    seriesGroup: {
        query: GetSeriesGroupAttributeDocument,
        editMutation: EditSeriesGroupAttributeDocument,
        deleteMutation: DeleteSeriesGroupAttributeDocument,
        checkNameQuery: CheckSeriesGroupNameExistsDocument,
        checkNameVariable: 'seriesGroupAttributeNameExists',
        checkNameExcludeKey: 'excludeSeriesGroupId',
    },
    seasonSubgroup: {
        query: GetSeasonSubgroupAttributeDocument,
        editMutation: EditSeasonSubgroupAttributeDocument,
        deleteMutation: DeleteSeasonSubgroupAttributeDocument,
        checkNameQuery: CheckSeasonSubgroupNameExistsDocument,
        checkNameVariable: 'seasonSubgroupAttributeNameExists',
        checkNameExcludeKey: 'excludeSeasonSubgroupId',
    },
} as const;

interface ICategoryPageProps {
    id: string,
    category: VideoAttribute,
}

interface IBu {
    id: string,
    name: string,
    __typename: string,
}

interface IRegion {
    id: string,
    name: string,
    iso3166_1_alpha_2: string,
    __typename: string,
}

type AttributeData = {
    [key in string]: {
        id: string,
        deletable: boolean,
        name: string,
        whitelistedBUs?: IBu[],
        whitelistedRegions?: IRegion[],
        __typename: string,
    }
};

type DefaultFormValues = {
    name: string,
    deletable: boolean,
    whitelistedBUs?: string[],
    whitelistedRegions?: string[],
    typename: string,
};

const getEmptyDefaultFormValues = (category: VideoAttribute) => {
    const defaultValues = {
        name: '',
    } as DefaultFormValues;

    if (category === 'uploadPolicy') {
        defaultValues.whitelistedBUs = [];
        defaultValues.whitelistedRegions = [];
    }

    return defaultValues;
};

const getDefaultFormValues = (
    data: AttributeData,
    category: VideoAttribute,
    queryType: VideoAttributeQueryType,
) => {
    const defaultValues = {
        name: data[videoAttributes[category][queryType]].name,
        deletable: data[videoAttributes[category][queryType]].deletable,
    } as DefaultFormValues;

    if (category === 'uploadPolicy') {
        const whitelistedBus = data[videoAttributes[category][queryType]]?.whitelistedBUs?.map(item => item.id);
        const whitelistedRegions = data[videoAttributes[category][queryType]]?.whitelistedRegions?.map(item => item.id);

        defaultValues.whitelistedBUs = [...(whitelistedBus || [])];
        defaultValues.whitelistedRegions = [...(whitelistedRegions || [])];
    }

    return defaultValues;
};

const CategoryPage = ({id, category}: ICategoryPageProps) => {
    const apolloClient = useApolloClient();
    const [editCategory, {loading: isSendingEdit}] = useMutation(queryConfig[category || 'type']?.editMutation);
    const [deleteCategory] = useMutation(queryConfig[category || 'type']?.deleteMutation);
    const {
        data,
        error,
        loading,
    } = useQuery(queryConfig[category || 'type']?.query, {
        variables: {
            id,
        },
    });
    const [
        getUploadPolicyParameters, {
            data: attributesData,
            error: queryError,
            loading: isLoading,
        },
    ] = useLazyQuery(GetUploadPolicyParametersDocument, {variables: {level: TeamLevel.Bu}});
    const categoryName = category ? videoAttributes[category].title : '';
    const attributeName = (data && category) ? data[videoAttributes[category][VideoAttributeQueryType.itemQueryType]]?.name : '';
    const [defaultValues, setDefaultValues] = useState(getEmptyDefaultFormValues(category));
    const [isReady, setIsReady] = useState(false);
    const formMethods = useForm<FieldValues>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: useMemo(() => defaultValues, [defaultValues]),
    });
    const {
        control,
        handleSubmit,
        reset,
        formState: {
            isDirty,
            isValid,
        },
    } = formMethods;
    const [discardWarningDisplayed, setDiscardWarningDisplayed] = useState(false);
    const [onDiscardWarningConfirm, setOnDiscardWarningConfirm] = useState<() => () => void>(() => () => {});
    const [deleteWarningDisplayed, setDeleteWarningDisplayed] = useState(false);
    const [onDeleteWarningConfirm, setOnDeleteWarningConfirm] = useState<() => () => void>(() => () => {});

    const interceptLinks = {
        status: !!(isDirty),
        action: () => {
            setDiscardWarningDisplayed(true);
        },
        setOnConfirm: setOnDiscardWarningConfirm,
    };

    useEffect(() => {
        if (category === 'uploadPolicy') {
            getUploadPolicyParameters();
        }
    }, [category]);

    useEffect(() => {
        if (!data || isReady) {
            return;
        }

        if (data[videoAttributes[category].itemQueryType] === null) {
            navigate('/categories', {
                state: {
                    toast: {
                        type: 'warning',
                        text: 'This category item doesn\'t exist',
                    },
                },
            });

            return;
        }

        const defaultData = getDefaultFormValues(data, category, VideoAttributeQueryType.itemQueryType);

        setDefaultValues(defaultData);
        reset(defaultData);
        setIsReady(true);
    }, [
        category,
        data,
        isReady,
        reset,
    ]);

    const onSubmit = (formData: FieldValues) => {
        const {deletable, ...sendData} = formData;

        if (category !== 'uploadPolicy') {
            delete sendData.whitelistedBUs;
            delete sendData.whitelistedRegions;
        }

        editCategory({
            variables: {
                id,
                input: sendData,
            },
            onCompleted(editData) {
                if (!editData) {
                    return;
                }

                toastify({
                    type: 'success',
                    text: 'Category item updated',
                });
                reset(getDefaultFormValues(editData, category, VideoAttributeQueryType.editQueryType));
            },
            onError(err) {
                toastify({
                    type: 'error',
                    text: `There was an error on item update: ${err.message}`,
                });
            },
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    };

    const handleDeleteCategoryItem = () => {
        setOnDeleteWarningConfirm(() => () => {
            deleteCategory({
                variables: {id},
                onCompleted(deleteData) {
                    if (!deleteData) {
                        return;
                    }

                    navigate('/categories', {
                        state: {
                            toast: {
                                type: 'success',
                                text: 'Category item deleted',
                            },
                        },
                    });
                },
                onError(err) {
                    toastify({
                        type: 'error',
                        text: `There was an error on item delete: ${err.message}`,
                    });
                },
                onQueryUpdated(observableQuery) {
                    return observableQuery.refetch();
                },
                update(cache) {
                    const normalizedId = cache.identify({id, __typename: defaultValues.typename});

                    cache.evict({id: normalizedId});
                    cache.gc();
                },
            });
        });

        setDeleteWarningDisplayed(true);
    };

    const checkNameExists = async (value: string | number) => {
        const {data: {[queryConfig[category].checkNameVariable]: nameExists}} = await apolloClient.query({
            query: queryConfig[category].checkNameQuery,
            variables: {name: value, [queryConfig[category].checkNameExcludeKey]: id},
        });

        return nameExists ? 'This name already exists' : false;
    };

    return (
        <Layout
            title={`${attributeName} (${categoryName})`}
            interceptLinks={interceptLinks}
        >
            {loading && ('Loading...')}
            {error && !data && (`Error! ${error.message}`)}
            {data && isReady && (
                <div className={styles.categoryWrapper}>
                    <div className={styles.header}>
                        <StatusBar
                            buttons={(
                                <>
                                    <Button
                                        text="Back to categories"
                                        icon={NavArrowLeftIcon}
                                        onClick={() => {
                                            if (isDirty) {
                                                setOnDiscardWarningConfirm(() => () => {
                                                    navigate('/categories');
                                                });
                                                setDiscardWarningDisplayed(true);

                                                return;
                                            }

                                            navigate('/categories');
                                        }}
                                        variant='outline'
                                    />
                                    <Button
                                        text="Save changes"
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={!isDirty || !isValid || isSendingEdit}
                                        loading={isSendingEdit}
                                    />
                                </>
                            )}
                            title={(
                                <span>{categoryName} <strong>/ {attributeName}</strong></span>
                            )}
                            search={false}
                        />
                    </div>
                    <div className={styles.attributeZone}>
                        <h1>{categoryName} item</h1>
                        <Form methods={formMethods}>
                            <Form.Row>
                                <Form.Column>
                                    <Input
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Name is required',
                                            },
                                        }}
                                        warningCallback={checkNameExists}
                                        title="Name"
                                        name="name"
                                        placeholder="name"
                                    />
                                </Form.Column>
                                <Form.Column>
                                    {category === 'uploadPolicy' && (
                                        <>
                                            {attributesData && attributesData.regions && (
                                                <MultiSelectBox
                                                    optionsValues={attributesData.regions.map(region => ({
                                                        id: region?.id,
                                                        value: region?.id,
                                                        label: region?.name,
                                                    }))}
                                                    title="Whitelisted regions (Youtube)"
                                                    placeholder="Choose the region"
                                                    controllerName="whitelistedRegions"
                                                    control={control}
                                                    canType={true}
                                                />
                                            )}
                                        </>
                                    )}
                                </Form.Column>
                            </Form.Row>
                            {category === 'uploadPolicy' && (
                                <>
                                    {isLoading && loading && ('Loading...')}
                                    {queryError && !attributesData && (`Error! ${queryError.message}`)}
                                    <Form.Row>
                                        <Form.Column>
                                            {attributesData && attributesData.teamsList && (
                                                <Switches<IBu>
                                                    data={attributesData.teamsList as IBu[]}
                                                    group="whitelistedBUs"
                                                    control={control}
                                                    title="Whitelisted BUs (IX)"
                                                />
                                            )}
                                        </Form.Column>
                                    </Form.Row>
                                </>
                            )}
                        </Form>
                    </div>
                    <div className={layoutStyles.pageSection}>
                        <h3>Danger zone</h3>
                        <Tooltip
                            text={`${categoryName} can't be deleted because it's being used!`}
                            disabled={defaultValues.deletable}
                        >
                            <Button
                                text="Delete this item"
                                color="error"
                                onClick={handleDeleteCategoryItem}
                                disabled={isSendingEdit || !defaultValues.deletable}
                                icon={DeleteIcon}
                            />
                        </Tooltip>
                    </div>
                    {discardWarningDisplayed && (
                        <WarningPopup
                            topText={[
                                'Whoa, wait!',
                                <br key="linebreak" />,
                                'You are about to discard all the changes. Do you really want to do that?',
                            ]}
                            buttons={(
                                <>
                                    <Button
                                        text="Yes, discard all changes"
                                        onClick={onDiscardWarningConfirm}
                                        color={'error'}
                                    />
                                    <Button
                                        text="No, take me back to editing"
                                        onClick={() => {
                                            setDiscardWarningDisplayed(false);
                                        }}
                                    />
                                </>
                            )}
                        />
                    )}
                    {deleteWarningDisplayed && (
                        <WarningPopup
                            topText={`Do you really wanna delete this ${categoryName.toLowerCase()}?`}
                            buttons={(
                                <>
                                    <Button
                                        text="Yes, delete it"
                                        onClick={onDeleteWarningConfirm}
                                        color={'error'}
                                    />
                                    <Button
                                        text="No, keep it"
                                        onClick={() => {
                                            setDeleteWarningDisplayed(false);
                                        }}
                                    />
                                </>
                            )}
                        />
                    )}
                </div>
            )}
        </Layout>
    );
};

export default CategoryPage;